// @mui
import { Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
//
import { MotivationIllustration } from '../../assets';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

// ----------------------------------------------------------------------

export default function Welcome() {
  return (
    <RootStyle>
      <CardContent
        sx={{
          color: 'grey.800',
          p: { md: 0 },
          pl: { md: 5 }
        }}
      >
        <Typography gutterBottom variant="h4">
          Hệ thống,
          <br /> ÔN THI GPLX
          <br />Ô TÔ, XE MÁY
        </Typography>

        <Typography variant="body2" sx={{ pb: { xs: 1, xl: 2 }, maxWidth: 480, mx: 'auto', mt: 2 }}>
          Những câu hỏi ôn tập và đề thi chuẩn nhất trong tháng. Bạn có thể tham gia ngay trong ngày hôm nay...
        </Typography>
      </CardContent>

      <MotivationIllustration
        sx={{
          p: 1,
          width: 200,
          margin: { xs: 'auto', md: 'inherit' }
        }}
      />
    </RootStyle>
  );
}
