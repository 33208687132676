// @mui
import { styled } from '@mui/material/styles';
// @mui
import { Box, Card, CardContent, CardMedia, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import Layout from '../layouts';
// components
import Page from '../components/Page';
// sections
import dynamic from 'next/dynamic';
import Slider from 'react-slick';
import Iconify from '../components/Iconify';
import TextMaxLine from '../components/TextMaxLine';
import useLocales from '../hooks/useLocales';
import useSettings from '../hooks/useSettings';
import Welcome from '../sections/home/Welcome';
const HomeSlider = dynamic(() => import('../sections/home/HomeSlider'));
const HomeMenu = dynamic(() => import('../sections/home/HomeMenu'));

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
  },
}));

HomePage.getLayout = function getLayout(page) {
  return <Layout variant="main">{page}</Layout>;
};

// ----------------------------------------------------------------------

export default function HomePage() {
  const { translate } = useLocales();
  const { themeStretch } = useSettings();
  return (
    <Page title={translate('index.title')} description={translate('index.description')}>
      <Container maxWidth={themeStretch ? 'xl' : 'lg'}>
        <RootStyle>
          <BannerSection />
          <HomeMenu />
          <JoinTeam />
          <Divider sx={{ my: 5 }} />
          <Reviews />
          <PriceTable />
        </RootStyle>
      </Container>
    </Page>
  );
}

function BannerSection() {
  return (
    <Grid container spacing={3} mb={6}>
      <Grid item xs={12} md={4}>
        <Welcome />
      </Grid>
      <Grid item xs={12} md={8}>
        <HomeSlider />
      </Grid>
    </Grid>);
}

export const JoinTeam = () => (
  <Container sx={{ mt: 10, textAlign: 'center' }}>
    <Typography variant="subtitle1" sx={{ mb: 2, color: 'text.error' }}>
      HỌC LÁI XE THÀNH CÔNG
    </Typography>
    <Typography variant="h3" sx={{ mb: 3, textTransform: 'capitalize' }}>
      Nhận Hồ Sơ & Bổ Túc Tay Lái
    </Typography>
    <Typography variant='h6' sx={{ mx: 'auto', maxWidth: 630, color: (theme) => (theme.palette.mode === 'light' ? 'text.secondary' : 'common.white'), fontWeight: 400 }} >
      Nhận hồ sơ lái xe uy tín, thân thiện, chi phí rõ ràng. Cam kết học đến khi đậu lấy bằng. Xin gọi về: 098.707.2219
    </Typography>
  </Container>
);

const data = [
  {
    id: 1,
    title: 'Nguyễn Anh Thơ - Sinh viên ngoại thương',
    description: "Hôm nay em nhận được bằng rồi! Thầy dạy rất có tâm và dễ hiểu. Em cám ơn trung tâm Long Biên đã giúp em đạt được mục tiêu lái xe",
    featureImage: "/daylaixe/user-1.jpg",
  },
  {
    id: 2,
    title: 'Bác Hoàng - Nhân viên kinh doanh',
    description: "Cảm giác lần đầu cầm vô lăng còn run cầm cập! Vậy mà bây giờ bon bon xe trên đường đi làm rồi. Thật biết ơn các thầy và trung tâm.",
    featureImage: "/daylaixe/user-2.jpg",
  },
  {
    id: 3,
    title: 'Phạm Đình Phương - Nhân viên văn phòng',
    description: "Mình bị trượt thực hành nhưng được các thầy tận tâm chỉ dạy lại chi tiết từng bài thi và lỗi sai. Rất biết ơn các thầy!",
    featureImage: "/daylaixe/user-3.jpg",
  },
  {
    id: 4,
    title: 'Nguyễn Anh Quân - Sinh viên học viện KMA',
    description: "Học lái xe nhìn dễ mà khó các bạn ạ. Mình cũng bị tâm lý may mà được các thầy trấn an và luyện tập nhiều nên giờ lái xe tốt rồi!",
    featureImage: "/daylaixe/user-4.jpg",
  },
];

const Reviews = () => {
  const settings = {
    speed: 1500,
    dots: true,
    arrows: false,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Grid container spacing={5} >
      <Grid item xs={12}>
        <Typography variant="h3" sx={{ color: 'text.error', textAlign: 'center' }}>
          <Iconify width={25} height={25} icon={'mdi:heart'} sx={{ color: 'red' }} /> Cảm nhận học viên <Iconify width={25} height={25} icon={'mdi:heart'} sx={{ color: 'red' }} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Slider {...settings}>
          {data.map(item => {
            const { displayName, title, description, featureImage } = item;
            return (
              <Box sx={{ px: 2 }} key={item.id}>
                <Card >
                  <CardMedia component="img" height="194" image={featureImage} alt={displayName} />
                  <CardContent>
                    <Typography gutterBottom variant="subtitle2" sx={{ textTransform: 'uppercase' }}>
                      {title}
                    </Typography>
                    <TextMaxLine variant={'body2'} line={3} persistent>
                      {description}
                    </TextMaxLine>
                  </CardContent>
                </Card>
              </Box>
            );
          })}
        </Slider>
      </Grid>
    </Grid>
  );
};

const PriceTable = () => (
  <Container sx={{ mt: 10, textAlign: 'center' }}>
    <Stack spacing={5}>
      <Typography variant="h4" >
        BẢNG GIÁ TRỌN GÓI
      </Typography>
      <Card >
        <CardMedia component="img" image={"/daylaixe/bang-gia.jpg"} alt={"Dạy lái xe"} />
      </Card>
    </Stack>
  </Container>
);